<template>
  <div class="company-detail-view">
    <div class="safty-wrapper">
      <div class="wrap-paper">
        <div class="sub-title-row">
          <div class="sub-title-cn">公司信息</div>
          <div class="sub-title-en">Company information</div>
        </div>

        <div class="company-baseinfo-row">
          <div class="company-picture">
            <img src="" alt="" />
          </div>
          <div class="company-base-content">
            <div class="company-code">公司代码： ISAX000010011088</div>
            <div class="company-title">XXXXXXXX技术有限公司</div>
            <div class="company-type">集成类公司</div>
          </div>
          <div class="company-detail-link-row">
            <a href="javascript:void(0)">了解详情</a>
          </div>
        </div>

        <div class="company-glory-row">
          <div class="company-glory-item">
            <div class="company-glory-label">系统集成资质</div>
            <div class="company-glory-content">
              <input type="radio" checked /><label>三级</label>
            </div>
          </div>
          <div class="company-glory-item">
            <div class="company-glory-label">CMMI等级</div>
            <div class="company-glory-content">
              <input type="radio" checked /><label>三级</label>
            </div>
          </div>
          <div class="company-glory-item">
            <div class="company-glory-label">信用等级</div>
            <div class="company-glory-content"></div>
          </div>
          <div class="company-glory-item">
            <div class="company-glory-label">高新企业认证</div>
            <div class="company-glory-content">
              <input type="radio" checked /><label>国家高新</label>
            </div>
          </div>
          <div class="company-glory-item">
            <div class="company-glory-label">其他资质/荣誉</div>
            <div class="company-glory-content other-glory-content">
              <div class="other-glory-content-item">
                <input type="checkbox" checked /><label>双软企业</label>
              </div>
              <div class="other-glory-content-item">
                <input type="checkbox" checked /><label>ISO-2007</label>
              </div>
            </div>
          </div>
        </div>

        <div class="company-business-row">
          <p>
            业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业务介绍业，，，，务介绍业务介绍业务介绍业绍
          </p>
          <p>
            介绍业务介绍业务介绍业务介绍业务介XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司XXXXXXX技术有限公司
          </p>
        </div>

        <div class="sub-title-row" style="margin-top: 64px">
          <div class="sub-title-cn">公司产品</div>
          <div class="sub-title-en">Company's product</div>
        </div>

        <div class="company-product-row">
          <div class="company-product-item" v-for="n in 9" :key="n">
            <div class="companu-product-wrap">
              <div class="company-product-image"></div>
              <div class="company-product-content">
                <div class="company-product-title">办公ERP软件</div>
                <div class="company-product-price">200,000,00/套</div>
                <div class="company-product-tags">
                  <span class="company-product-tags">技术服务</span>
                  <span class="company-product-tags">软件</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pagination">
          <el-pagination background layout="prev, pager, next" :total="100">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SwiperBanner from "./components/swiperBanner.vue";
export default {
  components: {
    SwiperBanner,
  },
  data() {
    return {
      banners: [],
    };
  },
  mounted() {
    this.getBanners();
  },
  methods: {
    getBanners() {
      this.$axios.get("/banner/showMemberPageBanner").then((res) => {
        if (res.state == 200) {
          this.banners = res.data || [];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.company-detail-view {
  background: #f9f9f9;
  .safty-wrapper {
    width: $safety-width;
    margin: 0 auto;
    padding: 125px 0 114px 0;
    .wrap-paper {
      background: #ffffff;
      padding: 56px 169px 64px 150px;
      .sub-title-row {
        display: flex;
        align-items: flex-end;

        padding-bottom: 32px;
        .sub-title-cn {
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            margin-top: 10px;
            width: 32px;
            height: 2px;
            background: #3883ff;
          }
        }
        .sub-title-en {
          color: #999999;
          font-size: 18px;
          margin-left: 17px;
        }
      }
    }

    .company-baseinfo-row {
      display: flex;
      .company-picture {
        width: 123px;
        height: 123px;
        background: #ddd;
      }
      .company-base-content {
        margin-left: 32px;
        flex: 1;
        color: #333333;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .company-code {
          font-size: 20px;
        }
        .company-title {
          font-size: 28px;
        }
        .company-type {
          color: #263238;
          font-size: 20px;
        }
      }
      .company-detail-link-row {
        display: flex;
        align-items: flex-end;

        a {
          color: #3883ff;
          font-size: 18px;
          text-decoration: none;
        }
      }
    }

    .company-glory-row {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      .company-glory-item {
        flex: 0 0 50%;
        display: flex;
        line-height: 40px;
        .company-glory-label {
          width: 120px;
        }
        .other-glory-content {
          display: flex;
          flex-wrap: wrap;
          .other-glory-content-item {
            margin-right: 20px;
          }
        }
      }
    }

    .company-business-row {
      margin-top: 30px;
      border: 1px solid #ededed;
      padding: 16px;

      color: #333333;
      line-height: 25px;
      font-size: 18px;
      p {
        text-indent: 2em;
      }
    }

    .company-product-row {
      display: flex;
      flex-wrap: wrap;
      .company-product-item {
        flex: 0 0 33%;
        // margin-right: 14px;
        .companu-product-wrap {
          border: 1px solid #efefef;
          display: flex;
          width: 283px;
          padding: 15px 12px 12px 12px;
          box-sizing: border-box;
          .company-product-image {
            width: 90px;
            height: 90px;
            background: #ddd;
          }
          .company-product-content {
            margin-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .company-product-title {
              font-size: 18px;
              color: #333333;
            }
            .company-product-price {
              font-size: 16px;
              color: #fa6400;
            }
            .company-product-tags {
              display: flex;
              .company-product-tags {
                margin-right: 5px;
                margin-bottom: 5px;
                padding: 6px 19px;
                font-size: 12px;
                box-sizing: border-box;
                background-color: #eeeeee;
              }
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 30px;
      text-align: right;
    }
  }
}
</style>