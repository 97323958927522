<template>
  <!-- <div class="index-view"> -->
  <div class="panel-detail">
    <template v-if="this.id == 1">
      <div class="index-panel" style="margin-top: 48px">
        <img src="@/assets/image/bidProject01.jpg" />
      </div>
      <div class="index-panel" style="margin: 48px 0">
        <img src="@/assets/image/bidProject02.jpg" />
      </div>
    </template>
    <template v-else-if="id == 2">
      <div class="index-panel" style="margin-top: 48px">
        <img src="@/assets/image/margin01.jpg" />
      </div>
      <div class="index-panel" style="margin: 48px 0">
        <img src="@/assets/image/margin02.jpg" />
      </div>
    </template>
    <template v-else-if="id == 3">
      <div class="index-panel" style="margin-top: 48px">
        <img src="@/assets/image/goods01.jpg" />
      </div>
      <div class="index-panel" style="margin: 48px 0">
        <img src="@/assets/image/goods02.jpg" />
      </div>
    </template>
    <div class="p-panel" style="margin: 48px 0" @click="handleClick()">
      立即办理
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {
  name: "panel-detail",
  data() {
    return {
      id: 1,
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    handleClick() {
      if (localStorage.user) {
        let user = JSON.parse(localStorage.user);
        if (user.type === 0) {
          this.$router.push("/a-company/bid-project/bid-project-preserve");
        } else {
          this.$message.warning("此业务属于集成类公司，请先注册！");
          this.$router.push("/register");
        }
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.panel-detail {
  width: $safety-width;
  margin: 0 auto;
  padding: 50px 0;
  position: relative;

  .index-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .p-panel {
    width: 200px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 34px;
    box-shadow: 4px 4px 4px #ddd;
    position: absolute;
    top: 70px;
    right: 50px;
    border-radius: 10px;
    background-color: rgba(34, 94, 170, 0.6);
    color: #fff;
  }
  .p-panel:hover {
    cursor: pointer;
    background-color: #004375;
  }
}
</style>